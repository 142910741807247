<template>
  <div :class="[ 'flex rounded-none lg:rounded-b-panel z-2 cursor-pointer bg-primary-dark', size === 'big' ? 'h-fit lg:h-125' : 'h-fit lg:h-57.5' ]">
    <div :class="[ 'w-full lg:w-137.5 h-full flex-shrink-0 bg-menu-dark lg:bg-primary-dark p-5 lg:p-13 rounded-none lg:rounded-bl-panel text-light', gridWidth >= 3 ? 'radial-gradient' : 'radial-gradient' ]">
      <Transition name="fade" mode="out-in">
        <div v-if="heading" class="w-full h-full flex flex-col items-center lg:items-start justify-center lg:justify-end" @click="handleClick">
          <h1 :class="[ 'mb-0 lg:mb-4 font-bold lg:font-normal text-center lg:text-left hyphens-none', size === 'big' && gridWidth > 2 ? 'text-6xl' : 'text-2xl' ]" v-html="$t(heading)" />
          <p :class="[ 'font-normal text-center sm:text-left hyphens-none', size === 'big' && gridWidth > 2 ? 'text-3xl' : 'text-base' ]" v-html="$t(subheading)" />
        </div>
      </Transition>
    </div>
    <div v-if="size === 'big' && gridWidth > 2" :class="[ 'hidden lg:flex w-full overflow-hidden z-1 rounded-br-panel', size === 'big' ? 'h-125' : 'h-57.5' ]">
      <Swiper
        id="bannerGallery"
        ref="bannerGallery"
        :space-between="0"
        :slides-per-view="1"
        :loop="true"
        :centered-slides="true"
        :pagination="{
          el: '.swiper-pagination',
          clickable: true,
          renderBullet: (index, className) => renderBullet(index, className)
        }"
        :speed="2000"
        class="w-full"
        :modules="[Autoplay, Pagination]"
        @click="handleClick"
        @swiper="onSwiper"
        @slideChange="onSlideChange"        
      >
        <SwiperSlide
          v-for="(item, idx) in gallery" 
          :key="`banner-gallery-${idx}`"
          class="w-full"
        >
          <div :class="[ 'relative w-full overflow-hidden flex justify-center items-center', size === 'big' ? 'h-125' : 'h-57.5'  ]">
            <div class="w-full h-auto absolute flex items-center justify-center" style="left: -1px; right: -1px; width: calc(100% + 2px);">
              <img 
                :src="item.image" 
                class="w-full h-auto absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2" 
                loading="lazy"
                style="width:100vw"
              />
            </div>
          </div>
        </SwiperSlide>
        <div class="swiper-pagination"></div>
      </Swiper>      
    </div>
  </div>
</template>

<script setup>
import { Autoplay, Pagination } from 'swiper/modules';
import Image from "@components/ui/image";

const props = defineProps({
  gallery: {
    type: Array,
    default: []
  },
  size: {
    type: String,
    default: 'normal'
  }
});

const ui = useUIStore();
const windowSize = useWindowSize();
const slideIndex = ref(-1);
const heading = ref(props.gallery[0].heading);
const subheading = ref(props.gallery[0].subheading);
const link = ref(props.gallery[0].link);
const bannerGallery = ref(null);

const gridWidth = computed(() => {
    if (process.client) {
        if (windowSize.width.value >= 1536) return 4;
        else if (windowSize.width.value >= 1024) return 3;
        else if (windowSize.width.value >= 768) return 2;
        else return 1;
    } 
    return 1;
});

const renderBullet = (index, className) => {
  return `<span class="${className}">&nbsp;</span>`;
};

const onSlideChange = (swiper) => {
  if (slideIndex.value >= 0) {
    heading.value = '';
    subheading.value = '';
  }
  slideIndex.value = swiper.realIndex;
  nextTick(() => {
    heading.value = props.gallery[slideIndex.value].heading;
    subheading.value = props.gallery[slideIndex.value].subheading;
    link.value = props.gallery[slideIndex.value].link;
  });
};

const handleClick = async () => {
  await navigateTo(translatePath(link.value));
}

const onSwiper = (swiper) => {
  setTimeout(() => {
    if (props.size === 'big' && gridWidth.value > 2 && swiper && swiper.params) {
      swiper.params.autoplay = {
        delay: 5000,
        disableOnInteraction: false,
      };
      swiper.autoplay.start();
    }
  }, 5000);
};

onMounted(() => {
  if (props.size === 'big' && gridWidth.value > 2) {
    heading.value = '';
    subheading.value = '';
    nextTick(() => {
      heading.value = props.gallery[0].heading;
      subheading.value = props.gallery[0].subheading;
      link.value = props.gallery[0].link;
    });
  }
});

</script>

<style scoped>
.radial-gradient {
  background-image: radial-gradient(circle at 30% 50%, #4B7F3D, #0D2712 70%);
}

.fade-enter-from, .fade-leave-to {
  opacity: 0;
}

.fade-enter-to, .fade-leave-from {
  opacity: 1;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity 1s ease;
}
</style>

<style>
.swiper-pagination-bullet {
  background: #F1F2EB;
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  margin: 0 0.3rem;
  opacity: 1;
}

.swiper-pagination-bullet-active {
  background: #A4FB7B;
}
</style>
