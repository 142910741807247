<template>
    <component :is="map[icon]" />
</template>

<script setup>
import Fish from "@components/icons/category/fish";
import Plants from "@components/icons/category/plants";
import Equipment from "@components/icons/category/equipment";
import Other from "@components/icons/category/other";

const props = defineProps({
    icon: {
        type: String,
        required: true
    }
})

const map = {
    Fish,
    Plants,
    Equipment,
    Other
}

</script>
