import * as Sentry from "@sentry/vue";
import { API_ENDPOINTS } from "@utils/api/endpoints";
import { useConfigStore } from '@stores/config';

export default async function () {
  const store = useConfigStore();

  if (store?.publicConfig?.length) return store?.publicConfig || [];

  const { data, error, status } = await useApiFetch(API_ENDPOINTS.CONFIG, {
    params: {
      public: true
    }
  });

  if (status.value === 'error') {
    sentryCaptureEvent({
      message: 'load-public-config',
      level: 'error',
      extra: { error: error.value }
    });
  } else {
    store.setPublicConfig(data.value?.items || []);

    return data.value?.items || [];
  }

  return [];
}
