import _ from 'lodash-es';
import { defineStore } from 'pinia'
import { CONFIG_KEY } from "@utils/constants";

export const useConfigStore = defineStore(CONFIG_KEY, () => {
  const publicConfig = ref([]);
  const setPublicConfig = (value) => {
    publicConfig.value = value;
  }

  const title = _.get(_.find(publicConfig.value || [], { identifier: 'title' }), 'value', null);
  const description = _.get(_.find(publicConfig.value || [], { identifier: 'description' }), 'value', null);

  return { 
    publicConfig, setPublicConfig,
    title, description
  }
})
